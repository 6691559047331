import React, { useState } from 'react'
import { format } from 'date-fns'
import { ChevronDown, ChevronUp, MessageCircle, Send } from 'lucide-react'

const Button = ({ children, variant, size, onClick, className }) => (
  <button
    className={`px-3 py-2 rounded-md text-sm font-medium transition-colors
      ${variant === 'ghost' ? 'hover:bg-gray-100 dark:hover:bg-gray-800' : 'bg-blue-500 text-white hover:bg-blue-600'}
      ${size === 'sm' ? 'text-xs' : ''}
      ${className}`}
    onClick={onClick}
  >
    {children}
  </button>
)

const Input = ({ type, value, onChange, onKeyPress, className, placeholder, style }) => (
  <input
    type={type}
    value={value}
    onChange={onChange}
    onKeyPress={onKeyPress} // 添加回车提交事件
    className={`border rounded-md px-3 py-2 w-full ${className}`}
    placeholder={placeholder}
    style={style}
  />
)

const Avatar = ({ src, fallback }) => (
  <div className="w-6 h-6 rounded-full overflow-hidden bg-gray-200 flex items-center justify-center">
    {src ? (
      <img src={src} alt="Avatar" className="w-full h-full object-cover" />
    ) : (
      <span className="text-xs font-semibold">{fallback}</span>
    )}
  </div>
)

const Card = ({ children, className }) => (
  <div className={`rounded-lg shadow ${className} overflow-hidden`}>
    {children}
  </div>
)

const CardHeader = ({ children, className }) => (
  <div className={`p-4 ${className}`}>{children}</div>
)

const CardContent = ({ children, className }) => (
  <div className={`px-4 py-2 ${className}`}>{children}</div>
)

const CardFooter = ({ children, className }) => (
  <div className={`p-4 ${className}`}>{children}</div>
)

const CommentComponent = ({ comment, onReply, level = 0, theme }) => {
  const [isExpanded, setIsExpanded] = useState(level < 2)
  const [replyText, setReplyText] = useState('')
  const [showReplyInput, setShowReplyInput] = useState(false)

  const handleReplySubmit = () => {
    if (replyText.trim()) {
      onReply(replyText, comment.id)
      setReplyText('')
      setShowReplyInput(false)
    }
  }


return (
    <Card className={`mb-2 ${level > 0 ? 'ml-4' : ''} ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
      <CardHeader className="py-2 flex flex-row items-center space-x-2">
        <Avatar
          src={comment.userAvatarUrl}
        />
        <div className="flex flex-col">
          <p className="text-sm font-semibold">{comment.userName}</p>
          <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
            {format(new Date(comment.commentTime), 'yyyy-MM-dd HH:mm')}
          </p>
        </div>
      </CardHeader>
      <CardContent className="py-2">
        <p className="text-sm truncate">{comment.commentText}</p>
      </CardContent>
      <CardFooter className="py-1 flex justify-between items-center">
        <Button variant="ghost" size="sm" onClick={() => setShowReplyInput(!showReplyInput)}>
          回复
        </Button>
        {comment.replies.length > 0 && (
          <Button variant={theme === 'dark' ? "ghost" : 'white'} size="sm" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
            {comment.replies.length} 条回复
          </Button>
        )}
      </CardFooter>
      {showReplyInput && (
        <CardFooter className="py-2">
          <Input
            type="text"
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleReplySubmit();
              }
            }}
            className={`border rounded-lg px-4 py-2 w-full ${theme === 'dark' ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'} shadow-md focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out`}
            placeholder="写下你的回复..."
            style={{ minHeight: '2.5rem' }}
          />
          <Button size="sm" onClick={handleReplySubmit}>
            <Send className="h-4 w-4" />
          </Button>
        </CardFooter>
      )}
      {isExpanded && comment.replies.map((reply) => (
        <CommentComponent key={reply.id} comment={reply} onReply={onReply} level={level + 1} theme={theme} />
      ))}
    </Card>
  )
}


const OptimizedComments = ({ comments, onReply, theme }) => {
  const [visibleComments, setVisibleComments] = useState(5)

  const loadMoreComments = () => {
    setVisibleComments((prev) => prev + 5)
  }

  return (
    <div className="space-y-4">
      <h2 className={`text-xl font-semibold flex items-center ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
        <MessageCircle className="mr-2" />
        评论 ({comments.length})
      </h2>
      {comments.slice(0, visibleComments).map((comment) => (
        <CommentComponent key={comment.id} comment={comment} onReply={onReply} theme={theme} />
      ))}
      {visibleComments < comments.length && (
        <Button variant="outline" onClick={loadMoreComments} className="w-full">
          加载更多评论
        </Button>
      )}
    </div>
  )
}

export default OptimizedComments