// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import PostDetail from './PostDetail';
import Personal from './Personal';
import MarkdownEditorWithPreview from './EditMarkdown';
import { ThemeProvider } from './components/ThemeContext';

function App() {
  return (
    <ThemeProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/posts/:id" element={<PostDetail />} />
            <Route path="/personal" element={<Personal />} />
            <Route path="/editMarkdown" element={<MarkdownEditorWithPreview />} />
            <Route path="/editMarkdown/:id" element={<MarkdownEditorWithPreview />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;