import React from 'react';  
import { useTheme } from './ThemeContext';  
  
function ThemeSwitcher() {  
  const { toggleTheme } = useTheme();  
  const buttonStyle = {  
    backgroundImage: 'url("https://file.hamburgerhan.com/主题切换.png")',  
    backgroundSize: 'cover',  
    width: '30px', 
    height: '30px',
    border: 'none',  
    cursor: 'pointer',  
    outline: 'none',  
  };  
  
  return (  
    <button style={buttonStyle} onClick={toggleTheme} aria-label="Switch Theme">   
    </button>  
  );  
}  
  
export default ThemeSwitcher;