import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Heart, MessageCircle } from 'lucide-react';
import { useTheme } from './components/ThemeContext';
import ThemeSwitcher from './components/ThemeSwitcher';
import LoginModal from './components/LoginModal';
import SignUpModal from './components/SignUpModal';
import UserInfoModal from './components/UserInfoModal';

function HomePage() {
  const [isMenuOpen] = useState(false);
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { theme } = useTheme();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isUserInfoModalOpen, setIsUserInfoModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);  
  const [userAvatarUrl, setUserAvatarUrl] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const color1 = (theme === 'dark') ? '300' : '900';
  const [likeMessage, setLikeMessage] = useState('');

  const openLoginModal = () => setIsLoginModalOpen(true);
  const closeLoginModal = () => setIsLoginModalOpen(false);
  const openSignUpModal = () => setIsSignUpModalOpen(true);
  const closeSignUpModal = () => setIsSignUpModalOpen(false);
  const openUserInfoModal = () => setIsUserInfoModalOpen(true);
  const closeUserInfoModal = () => setIsUserInfoModalOpen(false);

const handleLike = async (id) => {
  if (!isLoggedIn) {
    setLikeMessage('未登录用户无法评论和点赞。'); // 设置提示信息
    setTimeout(() => {
      setLikeMessage(''); // 一段时间后清除提示信息
    }, 3000); // 3秒后清除
    return; // 终止执行
  }
  const postIndex = posts.findIndex(post => post.id === id);
  const post = posts[postIndex];

  const newLikeStatus = post.likeStatus === 1 ? 2 : 1; // 切换状态
  const newLikeCount = post.likes + (newLikeStatus === 1 ? 1 : -1); // 更新点赞数量

  setPosts(prevPosts => {
    const updatedPosts = [...prevPosts];
    updatedPosts[postIndex] = { ...post, likeStatus: newLikeStatus, likes: newLikeCount };
    return updatedPosts;
  });

  try {
    await fetch('https://chat.hamburgerhan.com/posts/like', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userKey: localStorage.getItem('apiKey'),
        likeOperate: newLikeStatus, // 使用新的状态
        pageId: id,
        likeId: id,
      }),
    });
  } catch (error) {
    console.error('Error liking post:', error);
    // 如果出错，恢复原来的状态
    setPosts(prevPosts => {
      const revertedPosts = [...prevPosts];
      revertedPosts[postIndex] = { ...post, likeStatus: post.likeStatus, likes: post.likes };
      return revertedPosts;
    });
  }
};



  const handleLogin = (loggedIn, avatarUrl, name, email, userId) => {
    setIsLoggedIn(loggedIn);
    setUserAvatarUrl(avatarUrl);
    setUserInfo({ name, email, userId, avatar: avatarUrl });
  };

  const handleLogout = () => {
    localStorage.removeItem('userName');
    localStorage.removeItem('email');
    localStorage.removeItem('apiKey');
    localStorage.removeItem('userId');
    localStorage.removeItem('avatar');
    setIsLoggedIn(false);
    setUserAvatarUrl('');
    setUserInfo({});
  };

  useEffect(() => {
    const apiKey = localStorage.getItem('apiKey');
    const avatar = localStorage.getItem('avatarUrl');
    const name = localStorage.getItem('userName');
    const email = localStorage.getItem('email');
    const userId = localStorage.getItem('userId');

    if (apiKey) {
      setIsLoggedIn(true);
      setUserAvatarUrl(avatar);
      setUserInfo({ name, email, userId, avatar });
    }

    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    setIsLoading(true);
    const apiKey = localStorage.getItem('apiKey');
    try {
      const response = await fetch(`https://chat.hamburgerhan.com/posts?userKey=${apiKey}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setPosts(data.map(post => ({ ...post})));
    } catch (e) {
      setError('Failed to fetch posts. Please try again later.');
      console.error('Fetch error:', e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`min-h-screen flex flex-col ${theme}`}>
      {/* 顶部导航栏 */}
      <nav className={`bg-${theme} shadow-md sticky top-0 z-50`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <a href="/personal" className="text-2xl font-bold text-red-500">Hamburger</a>
            </div>
            <div className="flex items-center space-x-4">
              {isLoggedIn ? (
                <div className="flex items-center space-x-4">
                  <img
                    src={userAvatarUrl}
                    alt="User Avatar"
                    className="h-8 w-8 rounded-full cursor-pointer hover:ring-2 hover:ring-blue-500" 
                    onClick={openUserInfoModal}
                  />
                  <button
                    onClick={handleLogout}
                    className="text-gray-600 hover:text-blue-700 transition-colors"
                  >
                    退出
                  </button>
                  {userInfo.userId === '00000' && (
                    <Link to="/editMarkdown" className="text-gray-600 hover:text-blue-700 transition-colors">
                      写文
                    </Link>
                  )}
                </div>
              ) : (
                <>
                  <button
                    onClick={openLoginModal}
                    className="text-gray-600 hover:text-blue-700 transition-colors"
                  >
                    登录
                  </button>
                  <button
                    onClick={openSignUpModal}
                    className="text-gray-600 hover:text-blue-700 transition-colors"
                  >
                    注册
                  </button>
                </>
              )}
              <ThemeSwitcher />
            </div>
          </div>
        </div>
      </nav>

      {/* 提示信息 */}
    {likeMessage && (
      <div className="fixed top-0 left-1/2 transform -translate-x-1/2 bg-red-500 text-white text-center py-2 px-4 z-50">
        {likeMessage}
      </div>
    )}

      <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} onLogin={handleLogin} />
      <SignUpModal isOpen={isSignUpModalOpen} onClose={closeSignUpModal} onLogin={handleLogin} />
      <UserInfoModal isOpen={isUserInfoModalOpen} onClose={closeUserInfoModal} user={userInfo} />

      {/* 主要内容区 */}
      <main className="flex-grow container mx-auto py-6 px-4 sm:px-6 lg:px-8">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <p className="text-gray-500">加载中...</p>
          </div>
        ) : error ? (
          <div className="flex justify-center items-center h-64">
            <p className="text-red-500">{error}</p>
          </div>
        ) : (
<div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 max-w-6xl mx-auto">
  {posts.map((post) => (
    <div key={post.id} className="relative w-full rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
      <Link to={`/posts/${post.id}`}>
        <div className="aspect-w-1 aspect-h-1">
          <img src={post.image} alt={post.title} className="w-full h-full object-cover" />
        </div>
        
        <h3 className="text-lg font-semibold mb-1 line-clamp-2">{post.title}</h3> {/* 调整这里的 mb-2 为 mb-1 */}
        
      </Link>
      {/* 使用 flex 布局将喜欢按钮和评论数放在同一行 */}
      <div className="flex items-center justify-between">
        <button
          className={`flex items-center ${post.likeStatus === 1 ? 'text-red-500' : 'text-gray-' + color1} hover:text-red-500`}
          onClick={(e) => { e.stopPropagation(); handleLike(post.id); }}
        >
          <Heart className="h-6 w-6 mr-2" />
          <span>{post.likes}</span>
        </button>
        <div className="flex items-center text-gray-500 hover:text-gray-700" onClick={(e) => e.stopPropagation()}>
          <MessageCircle className="h-6 w-6 mr-2" />
          <span>{post.comments}</span>
        </div>
      </div>
    </div>
  ))}
</div>

        )}
      </main>

      {/* 页脚 */}
      <footer className={`bg-${theme} border-t border-gray-200 mt-auto`}>
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <p className="text-center text-gray-500 text-sm">
            © 2024 Hamburger. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;
