import { useState, useEffect, useRef } from 'react';
import { Github, Mail} from "lucide-react";
import { Link } from 'react-router-dom';
import { MessageCircle, ArrowLeft } from 'lucide-react';
import ThemeSwitcher from './components/ThemeSwitcher';
import { useTheme } from './components/ThemeContext';

export default function Personal() {
  const [showQRCode, setShowQRCode] = useState(false);
  const email = "hamburgerhan1@gmail.com";
  const wechatId = "SammyJax";
  const qrCodeRef = useRef(null);
  const { theme } = useTheme();

  const handleContactClick = (e) => {
    e.preventDefault();
    window.location.href = `mailto:${email}`;
  };

  const toggleQRCode = () => {
    setShowQRCode(!showQRCode);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (qrCodeRef.current && !qrCodeRef.current.contains(event.target)) {
        setShowQRCode(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [qrCodeRef]);

  return (
    <div className={`page ${theme}`}>
      <nav className={`bg-${theme} shadow-md`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <Link to="/" className="flex items-center text-gray-600 hover:text-red-500">
              <ArrowLeft className="h-6 w-6 mr-2" />
              返回
            </Link>
            <div class="d-flex justify-content-end mt-4">           
	<ThemeSwitcher /> {/* 添加全局主题切换按钮 */}
	</div>
            <div className="w-6"></div>
          </div>
        </div>
      </nav>
    <div className={`min-h-screen bg-${theme}-to-b from-gray-100 to-gray-200 flex items-center justify-center p-4`}>

      <div className={`w-full max-w-2xl bg-${theme} rounded-lg shadow-lg`}>
        <div className="p-6">
          <div className="flex flex-col items-center space-y-4">
            <h1 className="text-3xl font-bold text-center">Hamburger</h1>
            <p className="text-gray-500 text-center">Java开发 | 30+码农 | 喜欢撸狗</p>
            <div className="flex space-x-4">
              {/* GitHub 图标 */}
              <div className="relative group">
                <a href="https://github.com/hambuger" target="_blank" rel="noopener noreferrer">
                  <button className="p-2 border border-gray-300 rounded-md hover:bg-gray-100">
                    <Github className="h-4 w-4" />
                  </button>
                </a>
                <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 mb-2">
                  GitHub
                </span>
              </div>

              {/* 微信图标 */}
              <div className="relative group" ref={qrCodeRef}>
                <button className="p-2 border border-gray-300 rounded-md hover:bg-gray-100" onClick={toggleQRCode}>
                  <MessageCircle className="h-4 w-4" />
                </button>
                <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 mb-2">
                  {wechatId}
                </span>
                {showQRCode && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      width: '300px',
                      textAlign: 'center',
                    }}
                    className="bg-white p-2 rounded-lg shadow-lg"
                  >
                    <img
                      src="https://file.hamburgerhan.com/wechatQR.png"
                      alt="WeChat QR Code"
                      style={{ width: '300px', height: '300px' }}
                    />
                  </div>
                )}
              </div>

              {/* 邮件图标 */}
              <div className="relative group">
                <button className="p-2 border border-gray-300 rounded-md hover:bg-gray-100" onClick={handleContactClick}>
                  <Mail className="h-4 w-4" />
                </button>
                <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 mb-2">
                  {email}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">关于我</h2>
            <p className="text-gray-600 mb-4">
              你好！欢迎来到本站，随便看看吧。
            </p>
          </div>
        </div>
      </div>
    </div>
      <footer className={`bg-${theme} border-t border-gray-200 mt-8`}>
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <p className="text-center text-gray-500 text-sm">
            © 2024 Hamburger. All rights reserved.
          </p>
        </div>
      </footer>
</div>
  );
}
