import React, { useState, useEffect } from 'react';
import { useTheme } from './ThemeContext';  // 引入主题上下文

const SignUpModal = ({ isOpen, onClose, onLogin}) => {
  const { theme } = useTheme();  // 获取当前主题
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    if (isOpen) {
      setUserName('');
      setEmail('');
      setAvatar('');

      // 监听键盘事件，按下 ESC 键时关闭弹窗
      const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
          onClose();  // 调用关闭函数
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);  // 移除事件监听器
      };
    }
  }, [isOpen, onClose]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setAvatar(reader.result);  // 将图片文件的 Base64 数据存储到状态
    };

    if (file) {
      reader.readAsDataURL(file);  // 将文件读取为 Base64 数据
    }
  };

  const handleSubmit = async () => {

      // 前端校验用户名和邮箱
      if (!userName.trim()) {
        alert('用户名不能为空');
        return;
      }

      if (!email.trim()) {
        alert('邮箱不能为空');
        return;
      }
    try {
      const response = await fetch('https://chat.hamburgerhan.com/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userName:userName,
          userEmail:email,
          userAvatarBase64:avatar,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        // 成功，保存数据到 localStorage
        localStorage.setItem('userName', userName);
        localStorage.setItem('email', email);
        localStorage.setItem('apiKey', result.userKey);
        localStorage.setItem('userId', result.userId);
        localStorage.setItem('avatarUrl', result.userAvatarBase64);  // 存储头像 Base64
        onLogin(true, result.userAvatarBase64);
        onClose();  // 关闭弹窗
      } else {
        console.error('注册失败:', result.message);
      }
    } catch (error) {
      console.error('请求失败:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50`}>
      <div className={`bg-${theme === 'light' ? 'white' : 'gray-800'} p-6 rounded-lg shadow-lg max-w-md w-full relative`}>
        {/* 关闭按钮 */}
        <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700" onClick={onClose}>
          &times;
        </button>

        <h2 className={`text-${theme === 'light' ? 'gray-900' : 'white'} text-lg font-semibold mb-4`}>注册</h2>

        <div className="mb-4">
          <label className={`block text-sm font-medium text-${theme === 'light' ? 'gray-700' : 'gray-300'}`}>用户名</label>
          <input
            type="text"
            name="username"  // 添加 name 属性
            autoComplete="username"  // 添加自动填充属性
            className={`mt-1 p-2 border ${theme === 'light' ? 'border-gray-300' : 'border-gray-600'} rounded w-full bg-${theme === 'light' ? 'white' : 'gray-700'} text-gray-500`}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label className={`block text-sm font-medium text-${theme === 'light' ? 'gray-700' : 'gray-300'}`}>邮箱</label>
          <input
            type="email"
            name="email"  // 添加 name 属性
            autoComplete="email"  // 添加自动填充属性
            className={`mt-1 p-2 border ${theme === 'light' ? 'border-gray-300' : 'border-gray-600'} rounded w-full bg-${theme === 'light' ? 'white' : 'gray-700'} text-gray-500`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label className={`block text-sm font-medium text-${theme === 'light' ? 'gray-700' : 'gray-300'}`}>上传头像</label>
          <input type="file" onChange={handleFileChange} />
        </div>

        <div className="flex justify-end">
          <button className={`px-4 py-2 rounded bg-${theme === 'light' ? 'blue-500' : 'blue-600'} text-white`} onClick={handleSubmit}>
            注册
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpModal;
